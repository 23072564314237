// TODO: this can be broken down into a folder
// TODO: fix when opening the modal sometimes it focus on the close button
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import closeIcon from '../../assets/img/twd/close.png'

const Modal = ({ show, onHide, children, size }) => {
	let sizeClassName
	switch (size) {
		case 'lg':
			sizeClassName = 'max-w-4xl'
			break

		case 'sm':
			sizeClassName = 'max-w-md'
			break

		default:
			sizeClassName = 'max-w-2xl'
	}

	return (
		<>
			<Transition appear show={show} as={Fragment}>
				<Dialog as="div" className="relative z-[1000] w-fit" onClose={onHide}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-50" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel
									className={`${sizeClassName} relative flex max-h-[85vh] w-full transform flex-col overflow-hidden rounded-lg border-2 border-[#77818c] bg-white text-left align-middle shadow-2xl transition-all`}
								>
									{children}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

const Header = ({ children, className, onHide }) => {
	return (
		<div
			className={`flex-start sticky left-0 top-0 z-50 flex w-full justify-between border-b-2 border-[#f6f8f9] bg-white ${className}`}
			style={{ boxShadow: 'rgba(246, 248, 249, 0.4) 0px 8px 24px' }}
		>
			<div className="my-auto flex-1 py-4 pl-4 font-[#d7dde4] text-2xl font-medium">{children}</div>
			<button className="float-right mb-auto px-3 py-4" onClick={onHide}>
				<img className="w-10" style={{ minWidth: 34, minHeight: 34 }} src={closeIcon} alt="Close" />
			</button>
		</div>
	)
}

const Body = ({ children, className }) => {
	return <div className={`overflow-y-scroll p-4 ${className}`}>{children}</div>
}

const Footer = ({ children, className }) => {
	return (
		<div
			className={`sticky bottom-0 left-0 z-[35] w-full border-t-2 border-[#f6f8f9] bg-white p-4 ${className}`}
			style={{ boxShadow: 'rgba(246, 248, 249, 0.6) 0px -12px 24px' }}
		>
			{children}
		</div>
	)
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export default Modal
