const useGetManagerTeam = () => {
	const getManagerTeam = async (email: string, token: string) => {
		const response = await fetch(`/health/api/teams/manager/${email}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		})

		if (response.status === 204) return

		const json = await response.json()

		if (!response.ok) throw Error(json.error)

		// NOTE: if user is the manager of teams, return the sudo team
		if (json && json.length >= 0) return json[0]
	}

	return { getManagerTeam }
}

export default useGetManagerTeam
