// NOTE: hooks
import { useAuthContext } from '../../hooks/useAuthContext'
import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
// NOTE: components
import Layout from '../../components/ui/Layout'
import Loading from '../../components/ui/Loading'
// NOTE: images
import homeIcon from '../../assets/img/navIcons/homeGradient.png'
import adminIcon from '../../assets/img/logo/adminIcon.png'
// NOTE: main pages
const Home = lazy(() => import('./Home'))

const navLinks = [
	{
		to: '/admin/',
		text: 'Home',
		icon: homeIcon
	}
]

const LoadPage = ({ page }) => {
	const { dispatch } = useAuthContext()
	const user = JSON.parse(localStorage.getItem('user'))

	// NOTE: log user out when it has the old user object set on login
	if (user?.displayName || user?.position) {
		localStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
	} else {
		// NOTE: redirect to page if user exists with admin permission and does not have the old object
		if (user?.admin > 0) return <Suspense fallback={<Loading />}>{page}</Suspense>
	}

	const prevSearch = window.location.search
	const search = `${prevSearch ? `${prevSearch}&` : '?'}redirect=${window.location.pathname}`

	return (
		<Navigate
			to={{
				pathname: '/auth',
				search: search
			}}
		/>
	)
}

const adminRoutes = [
	{
		path: 'admin',
		element: <Layout links={navLinks} home="/admin/" icon={adminIcon} />,
		children: [
			{
				index: true,
				element: <LoadPage page={<Home />} />
			}
		]
	}
]

export default adminRoutes
