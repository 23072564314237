// TODO: hooks
import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import useGetManagerTeam from '../../hooks/api/useGetManagerTeam'
import { useNotification } from '../../hooks/useNotification'
// NOTE: components
import NavBar from './NavBar'
import ManagerNavBar from './ManagerNavBar'
import StaffInvitation from '../staffInvitation/StaffInvitation'
import Loading from './Loading'

/**
 * Display the page with the appropriate navbar and perform actions common to all the pages in the different dashboards
 * 	Staff dashboard: check if they were invited to join a company
 * 	Manager dashboard: check if the company context needs to be updated
 *
 * @param {String} links - links to display on the navbar
 * @param {String} home - link to the home page of the dashboard
 * @param {String} icon - icon to display at the top of the desktop navbar
 * @param {String} dashboard - 'staff' | 'manager' | 'clinician' | 'admin : a text describing which dashboard we're displaying
 */

const Layout = ({ links, home, icon, dashboard }) => {
	const { user, dispatch } = useAuthContext()
	const { getManagerTeam } = useGetManagerTeam()
	const { notify } = useNotification()
	const [loading, setLoading] = useState(true)

	const getData = async () => {
		try {
			const team = await getManagerTeam(user.email, user.token)

			dispatch({ type: 'UPDATE_COMPANY', payload: team })
		} catch (error) {
			notify('danger', `Couldn't load your company: ${error.message}`)
		} finally {
			setLoading(false)
		}
	}

	// NOTE: when on the manager dashboard update the team on every page refresh in case their permission or team details updates
	useEffect(() => {
		if (user && dashboard === 'manager') {
			getData()
		} else {
			setLoading(false)
		}
	}, [])

	if (loading) return <Loading />

	if (dashboard === 'manager' && !user?.team) return <Navigate to="/manager/onboarding" />

	return (
		<>
			{/*<StaffInvitation />*/}
			<div className="flex min-h-screen min-h-svh">
				{dashboard === 'manager' ? (
					<ManagerNavBar links={links} home={home} />
				) : (
					<NavBar links={links} home={home} icon={icon} dashboard={dashboard} />
				)}
				<Outlet />
			</div>
		</>
	)
}

export default Layout
