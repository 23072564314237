const authEndpoint = '/health/api/auth'

// TODO: specify the 'data' type with all the needed fields
const login = async (data: any) => {
	if (!data) throw Error('No auth data provided')

	return await fetch(`${authEndpoint}/login`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	})
}

// TODO: specify the 'data' type with all the needed fields
const register = async (data: any) => {
	if (!data) throw Error('No auth data provided')

	return await fetch(`${authEndpoint}/register`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	})
}

// TODO: merge resetPwd and changePwd in the backend instead of having two controllers that do the same thing
// TODO: specify the 'data' type with all the needed fields
// NOTE: save new password - implemented in auth forms using jwt token to validate user by sending them an email
const resetPwd = async (data: any) => {
	if (!data) throw Error('No auth data provided')

	return await fetch(`${authEndpoint}/reset`, {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	})
}

// TODO: specify the 'data' type with all the needed fields
// TODO: unify sending the reset password email with the getCode controller in backend
// NOTE: send email to recover password with jwt toke - implemented in auth forms
const sendToken = async (data: any) => {
	if (!data) throw Error('No auth data provided')

	return await fetch(`${authEndpoint}/reset`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	})
}

export { login, register, resetPwd, sendToken }
