// NOTE: hooks
import { useAuthContext } from '../../hooks/useAuthContext'
import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
// NOTE: components
import Loading from '../../components/ui/Loading'
import Layout from '../../components/ui/Layout'
// NOTE: images
import clinicianIcon from '../../assets/img/logo/clinicIcon.png'
import clinicIcon from '../../assets/img/navIcons/peopleGradient.png'
// NOTE: main pages
const Patient = lazy(() => import('./Patient'))

const navLinks = [
	{
		to: '/clinician/patients/',
		text: 'Patients',
		icon: clinicIcon
	}
]

// TODO: create this as a component
const LoadPage = ({ page }) => {
	const { dispatch } = useAuthContext()
	const user = JSON.parse(localStorage.getItem('user'))

	// NOTE: log user out when it has the old user object set on login
	if (user?.displayName || user?.position) {
		localStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
	} else {
		// NOTE: redirect to page if user exists with admin or clinician permission and does not have the old object
		if (user?.clinician > 0 || user?.admin > 0)
			return <Suspense fallback={<Loading />}>{page}</Suspense>
	}

	const prevSearch = window.location.search
	const search = `${prevSearch ? `${prevSearch}&` : '?'}redirect=${window.location.pathname}`

	return (
		<Navigate
			to={{
				pathname: '/auth',
				search: search
			}}
		/>
	)
}

const clinicianRoutes = [
	{
		path: 'clinician',
		element: <Layout links={navLinks} home="/clinician/" icon={clinicianIcon} />,
		children: [
			{
				index: true,
				element: <Navigate to="patients/" />
			},
			{
				path: 'patients',
				element: <LoadPage page={<Patient />} />
			}
		]
	}
]

export default clinicianRoutes
