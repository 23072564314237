import ReactLoading from 'react-loading'

// TODO: remove the references to this and attempt to use skeletons in the components instead of having a global loading on the whole page

const Loading = ({ size }) => (
	<div
		className="m-auto flex items-center justify-center"
		style={{ width: '100%', height: '100%' }}
	>
		<ReactLoading type={'bubbles'} height={size ?? 100} width={size ?? 100} color={'#99a5b3'} />
	</div>
)

export default Loading
