const Container = ({ className, children, style }) => {
	const styles = {
		container: {
			flex: 1, // fill layout height
			flexGrow: 1, // fill layout width
			maxWidth: '1280px',
			overflowX: 'clip'
		}
	}

	return (
		<div
			className={`${className} overflow-hidden px-4 pb-[100px] md:px-6 lg:px-12`}
			style={{ ...styles.container, ...style }}
		>
			{children}
		</div>
	)
}

export default Container
