// NOTE: hooks
import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
// NOTE: components
import Layout from '../../components/ui/Layout'
import Loading from '../../components/ui/Loading'
import ManagerSubPage from '../../components/ui/ManagerSubPage'
// NOTE: images
import homeIcon from '../../assets/img/twd/home.png'
import settingsIcon from '../../assets/img/twd/settings.png'
import exploreIcon from '../../assets/img/twd/resources.png'

// NOTE: main pages
const Settings = lazy(() => import('./Settings'))
const HealthOverview = lazy(() => import('./HealthOverview'))
const FinanceOverview = lazy(() => import('./FinanceOverview'))
const Managers = lazy(() => import('./Managers'))
const Explore = lazy(() => import('./Explore'))
const Onboarding = lazy(() => import('./Onboarding'))
const Setup = lazy(() => import('./Setup'))
const Staff = lazy(() => import('./Staff'))
const CreateTeam = lazy(() => import('./CreateTeam'))

const navLinks = [
	{
		to: '/manager/team',
		text: 'Home',
		icon: homeIcon
	},
	{
		to: '/manager/explore',
		text: 'Explore',
		icon: exploreIcon
	},
	{
		to: '/manager/settings',
		text: 'Settings',
		icon: settingsIcon
	}
]

const homeLinks = [
	{
		to: 'staff',
		text: 'Staff'
	},
	{
		to: 'health',
		text: 'Health'
	},
	{
		to: 'finance',
		text: 'Finance'
	},
	{
		to: 'managers',
		text: 'Managers'
	},
	{
		to: 'setup',
		text: 'Setup'
	}
]

// NOTE: component to show loading animation and handle authorisation
const LoadPage = ({ page }) => {
	const { dispatch } = useAuthContext()
	const user = JSON.parse(localStorage.getItem('user'))

	// NOTE: log user out when it has the old user object set on login
	if (user?.displayName || user?.position) {
		localStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
	} else {
		// NOTE: redirect to page if user exists and does not have the old object
		if (user) return <Suspense fallback={<Loading />}>{page}</Suspense>
	}

	// NOTE: redirect login page with the previous url search parameters and the redirect parameter so auth knows where to redirect the user after login
	const prevSearch = window.location.search
	const search = `${prevSearch ? `${prevSearch}&` : '?'}redirect=${window.location.pathname}`

	return (
		<Navigate
			to={{
				pathname: '/auth',
				search: search
			}}
		/>
	)
}

const managerRoutes = [
	{
		index: true,
		element: <Navigate to="/manager/" />
	},
	{
		path: 'manager',
		element: <Layout links={navLinks} home="/manager/" dashboard="manager" />,
		children: [
			{
				index: true,
				element: <Navigate to="team" />
			},
			{
				path: 'team',
				element: <ManagerSubPage title="Home" src={homeIcon} links={homeLinks} />,
				children: [
					{
						index: true,
						element: <Navigate to="staff" />
					},
					{
						path: 'staff',
						element: <LoadPage page={<Staff />} />
					},
					{
						path: 'health',
						element: <LoadPage page={<HealthOverview />} />
					},
					{
						path: 'finance',
						element: <LoadPage page={<FinanceOverview />} />
					},
					{
						path: 'managers',
						element: <LoadPage page={<Managers />} />
					},
					{
						path: 'setup',
						element: <LoadPage page={<Setup />} />
					}
				]
			},
			{
				path: 'explore',
				element: <LoadPage page={<Explore />} />
			},
			{
				path: 'settings',
				element: <LoadPage page={<Settings />} />
			}
		]
	},
	{
		path: 'manager/onboarding',
		element: <LoadPage page={<Onboarding />} />
	},
	{
		path: 'manager/create-team',
		element: <LoadPage page={<CreateTeam />} />
	}
]

export default managerRoutes
