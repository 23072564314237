// NOTE: hooks
import useStaffInvitation from './useStaffInvitation.js'
// NOTE: components
import Modal from '../ui/Modal.js'
import ReactLoading from 'react-loading'

const StaffInvitation = () => {
	const { invitation, show, loading, close, accept, decline } = useStaffInvitation()

	return (
		<>
			{invitation && (
				<Modal size="sm" show={show} onHide={close}>
					<Modal.Header onHide={close}>{`Join the ${invitation.name} team`}</Modal.Header>

					<Modal.Body>
						<p>{`${invitation.name} invites you to join their team, providing additional support and becoming part of their community of wellbeing champions.`}</p>
					</Modal.Body>
					<Modal.Footer className="flex">
						<div className="ml-auto flex">
							<button
								className="mr-4 rounded-full bg-[#e8f2f4] px-8 py-2 font-medium text-[#105767] shadow-sm"
								disabled={loading !== 'INITIAL'}
								onClick={decline}
							>
								{loading !== 'DECLINING' && 'Decline'}

								{loading === 'DECLINING' && (
									<div
										style={{ height: 26 }}
										className="m-0 flex w-full flex-wrap justify-center p-0"
									>
										<ReactLoading type={'spin'} color={'#334251'} width={'1.5rem'} delay={100} />
									</div>
								)}
							</button>

							<button
								className="rounded-full bg-gradient-to-r from-[#105767] to-[#147084] px-10 py-2 text-white shadow-lg"
								disabled={loading !== 'INITIAL'}
								onClick={accept}
							>
								{loading !== 'ACCEPTING' && 'Accept'}

								{loading === 'ACCEPTING' && (
									<div
										style={{ height: 26 }}
										className="m-0 flex w-full flex-wrap justify-center p-0"
									>
										<ReactLoading type={'spin'} color={'#334251'} width={'1.5rem'} delay={100} />
									</div>
								)}
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			)}
		</>
	)
}

export default StaffInvitation
